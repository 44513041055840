import { ref } from '@vue/composition-api'

import axiosClient from '@/helpers/axios'
import useAlertNotification from '@/composables/notification/useAlert'
import useSweetAlertNotification from '@/composables/notification/useSweetAlert'
// eslint-disable-next-line import/no-cycle

export default function useCategorieSocietes() {
  const { successToast } = useAlertNotification()
  const { errorSweetAlert } = useSweetAlertNotification()
  const categorieSociete = ref([])
  const loader = ref(false)
  const categorieSocieteProcess = ref(false)
  const categorieSocieteSuccess = ref(false)

  const categorieSocietes = ref([])
  const errors = ref('')

  const getAllCategorieSocietes = async () => {
    try {
      loader.value = true
      const response = await axiosClient.get('/get-all-categorie-societes')
      if (response.data.success === true) {
        loader.value = false
        categorieSocietes.value = response.data.data
      }
    } catch (error) {
      loader.value = false
    }
  }

  const getCategorieSocieteById = async id => {
    try {
      loader.value = true
      const response = await axiosClient.get(`/get-categorie-societe-by-id/${id}`)
      if (response.data.success === true) {
        categorieSociete.value = response.data.data
        loader.value = false
      }
    } catch (error) {
      loader.value = false
    }
  }

  const createCategorieSociete = async data => {
    try {
      errors.value = ''
      categorieSocieteProcess.value = true
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.post('/store-categorie-societe', data)
      if (response.data.success === true) {
        successToast('Enregistrement', response.data.message)
        categorieSocieteSuccess.value = true
        categorieSocieteProcess.value = false
        categorieSociete.value = response.data.data
        await getAllCategorieSocietes()
      }
    } catch (error) {
      categorieSocieteProcess.value = false
      categorieSocieteSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }
  const updateCategorieSociete = async data => {
    try {
      errors.value = ''
      categorieSocieteProcess.value = true
      // eslint-disable-next-line no-prototype-builtins
      const response = await axiosClient.patch(`/update-categorie-societe/${data.id}`, data)
      if (response.data.success === true) {
        successToast('Modification', response.data.message)

        categorieSocieteSuccess.value = true
        categorieSocieteProcess.value = false
        categorieSociete.value = response.data.data
        await getAllCategorieSocietes()
      }
    } catch (error) {
      categorieSocieteProcess.value = false
      categorieSocieteSuccess.value = false
      loader.value = false
      errors.value = ''
      errors.value = error.response.data.errors
      // eslint-disable-next-line no-prototype-builtins
      if (error.response.data.hasOwnProperty('message')) errorSweetAlert('Oups! Erreur', error.response.data.message)
      const responseErrors = error.response.data.errors
      Object.keys(responseErrors).forEach(key => {
        errorSweetAlert('Oups! Erreur', responseErrors[key][0])
      })
    }
  }

  return {
    errors,
    categorieSociete,
    categorieSocietes,
    categorieSocieteProcess,
    loader,
    categorieSocieteSuccess,
    getAllCategorieSocietes,
    getCategorieSocieteById,
    createCategorieSociete,
    updateCategorieSociete,

  }
}
