<!-- eslint-disable vue/valid-v-bind -->
<template>
  <b-row>

    <b-col md="12">
      <b-card
        title="Sociétés"
        no-body
      >
        <b-card-body>
          <b-row>
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="gradient-danger"
                size="sm"
                pill
                class="float-right mb-1"
                onclick="history.back()"
              >
                <feather-icon icon="ArrowLeftCircleIcon" />
                Retour
              </b-button>
              <b-button
                id="toggle-btn"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal-societe
                class="float-left"
                variant="gradient-success"
                size="sm"
                pill
              >
                Créer une société
              </b-button>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-between  flex-wrap">

            <!-- sorting  -->
            <b-form-group
              label="Filtrer"
              label-size="sm"
              label-align-sm="left"
              label-md-sm="3"
              label-for="sortBySelect"
              class=" mb-md-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">
                      aucun
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                >
                  <option :value="false">
                    Croissant
                  </option>
                  <option :value="true">
                    Décroissant
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>

            <!-- filter -->
            <b-form-group
              label="Rechercher"
              label-md-sm="3"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Rechercher"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Effacer
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </b-card-body>

        <b-table
          striped
          hover
          responsive
          small
          :busy="loader"
          class="position-relative"
          :per-page="perPage"
          empty-text="Aucun résultat trouvé"
          empty-filtered-text="Aucun résultat trouvé"
          show-empty
          :current-page="currentPage"
          :items="societes"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #table-busy>
            <div class="d-flex justify-content-center mb-3">
              <b-spinner
                style="width: 2rem; height: 2rem;"
                variant="primary"
                label="Large Spinner"
                type="grow"
                class="mt-2"
              />
              <span class="sr-only">Chargement en cours...</span>
            </div>
          </template>
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(status)="data">
            <div>
              <b-badge
                pill
                :variant="`light-${statusSocieteVariant(data.item.status)}`"
              >
                {{ data.item.status }}

              </b-badge>
            </div>
          </template>
          <template #cell(manager.name)="data">
            {{ data.item.manager_id? data.item.manager.name:'Non défini' }}
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-tooltip.hover.bottom="'Voir le détail '"
              variant="gradient-primary"
              size="sm"
              class="btn-icon rounded-circle ml-1"
              :to="{name:'espace-super-admin.detail-societe', params:{id:data.item.id}, replace:true}"
            >
              <feather-icon icon="ListIcon" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-modal.modal-add-respo
              v-b-tooltip.hover.bottom="data.item.manager_id? 'Changer le responsable': 'Ajouter un responsable'"
              variant="gradient-dark"
              size="sm"
              class="btn-icon rounded-circle ml-1"
              @click.prevent="getItem(data.item)"
            >
              <feather-icon icon="UserIcon" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-tooltip.hover.bottom="'Modifier une société'"
              v-b-modal.modal-societe
              variant="gradient-warning"
              size="sm"
              class="btn-icon rounded-circle ml-1"
              @click.prevent="getItem(data.item)"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-tooltip.hover.bottom="'Supprimer une société'"
              variant="gradient-danger"
              size="sm"
              class="btn-icon rounded-circle ml-1"
              @click.prevent="deletingSociete(data.item.id)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

          <!-- page length -->
          <b-form-group
            label="Par Page"
            label-md="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="societes.length"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class=" pagination-primary mb-0 pagination-center"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>

      </b-card>
    </b-col>

    <b-modal
      id="modal-societe"
      ref="myModal"
      no-close-on-backdrop
      button-size="sm"
      :modal-class="form.id ? 'modal-warning' : 'modal-success'"
      :title="form.id ? 'Modifier une société' : 'Ajouter une société'"
      :busy="societeProcess"
      :ok-title="form.id ? 'Modifier' : 'Ajouter'"
      cancel-title="Fermer"
      cancel-variant="danger"
      :ok-variant="form.id ? 'warning' : 'success'"
      size="lg"
      @hidden="resetForm"
      @ok="submitForm"
    >
      <validation-observer ref="societeFormRules">
        <b-form>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Raison sociale"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="raison sociale"
                  rules="required"
                >
                  <b-form-input
                    id="company_name"
                    v-model="form.company_name"
                    :formatter="formatter"
                    size="sm"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Raison sociale"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Sélectionner une catégorie de société"
                label-for="categorie_societe_id"
              >
                <validation-provider
                  #default="{ errors }"
                  name="catégorie de société"
                  rules="required"
                >
                  <v-select
                    id="categorie_societe_id"
                    v-model="form.categorie_societe_id"
                    label="title"
                    size="sm"
                    :state="errors.length > 0 ? false : null"
                    :options="categorieSocietes"
                    placeholder="Sélectionner une catégorie de  société"
                    :reduce="(categorieSocietes) => categorieSocietes.id"
                    class="select-size-sm"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Email "
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email "
                  rules="required"
                >
                  <b-form-input
                    id="email"
                    v-model="form.email"
                    :state="errors.length > 0 ? false : null"
                    size="sm"
                    placeholder="Email "
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Siège social"
                label-for="headquarter"
              >
                <validation-provider
                  #default="{ errors }"
                  name="siège social"
                  rules="required"
                >
                  <b-form-input
                    id="headquarter"
                    v-model="form.headquarter"
                    :state="errors.length > 0 ? false : null"
                    size="sm"
                    :formatter="formatter"
                    placeholder="Siège social"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Régistre de commerce "
                label-for="trade_register"
              >
                <b-form-input
                  id="trade_register"
                  v-model="form.trade_register"
                  size="sm"
                  placeholder="Régistre de commerce "
                />

              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Identifiant Fiscal Unique"
                label-for="ifu"
              >
                <validation-provider
                  #default="{ errors }"
                  name="identifiant fiscal unique"
                  rules="required"
                >
                  <b-form-input
                    id="ifu"
                    v-model="form.ifu"
                    :state="errors.length > 0 ? false : null"
                    size="sm"
                    :formatter="formatter"
                    placeholder="Identifiant fiscal unique"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              md="6"
              class="mt-md-2"
            >
              <b-form-group
                label="Téléphone"
                label-for="phone"
              >
                <validation-provider
                  #default="{ errors }"
                  name="téléphone"
                  rules="required"
                  type="tel"
                >
                  <VuePhoneNumberInput
                    v-model="form.telephonefull"
                    default-country-code="BJ"
                    show-code-on-list
                    size="sm"
                    :translations="configPhoneNumber"
                    @update="telephonefull = $event"
                  />

                  <small class="text-danger ml-1 mt-2">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              md="6"
            >
              <b-form-group
                label="Description "
                label-for="description"
              >
                <b-form-textarea
                  id="description"
                  v-model="form.description"
                  placeholder="Description "
                  rows="3"
                />

              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-modal
      id="modal-add-respo"
      ref="myModal"
      no-close-on-backdrop
      button-size="sm"
      modal-class="modal-dark"
      title="Ajouter un responsable à la société"
      :busy="societeProcess"
      ok-title="Ajouter"
      cancel-title="Fermer"
      cancel-variant="danger"
      ok-variant="success"
      size="md"
      :ok-disabled="user.length === 0"
      @hidden="resetForm"
      @ok="addManager"
    >
      <validation-observer
        #default="{ invalid }"
        ref="societeFormRules"
      >
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Email ou numéro matricule du responsable"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email ou numéro matricule"
                  rules="required"
                >
                  <b-input-group>
                    <b-form-input
                      id="email"
                      v-model="formSearch.email"
                      :state="errors.length > 0 ? false : null"
                      size="sm"
                      placeholder="Email ou numéro matricule du responsable"
                    />
                    <b-input-group-append>
                      <b-button
                        size="sm"
                        variant="primary"
                        :disabled="invalid|| userProcess"
                        @click.prevent="searchUserSubmitModal"
                      >
                        Rechercher
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <b-list-group-item
                    v-if="user.length !== 0"
                    class="d-flex justify-content-between align-items-center mt-1"
                  >
                    <div class="d-flex">
                      <b-avatar
                        :text="avatarText(user.name)"
                        variant="primary"
                      />
                      <div class="ml-25">
                        <b-card-text class="font-weight-bold mb-0">
                          {{ user.name }}
                        </b-card-text>
                        <small>{{ user.email }}</small>
                      </div>
                    </div>
                  </b-list-group-item>
                  <b-alert
                    v-if="!foundUser"
                    variant="danger"
                    class="mt-1"
                    show
                  >
                    <div class="alert-body">
                      <strong>Utilisateur introuvable,</strong>
                      <b-button
                        size="sm"
                        variant="primary"
                        to="/espace-super-admin/utilisateur"
                        class="float-right"
                      >
                        le créer
                      </b-button>
                    </div>
                  </b-alert>
                </validation-provider>
              </b-form-group>

            </b-col>

          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  ref, onMounted, reactive, watch, computed,
} from '@vue/composition-api'
import vSelect from 'vue-select'
import {
  VBTooltip, BRow, BCol, BCard, BTable, BFormGroup, BFormSelect, BBadge, BPagination, BInputGroup,
  BInputGroupAppend, BButton, BCardBody, BFormInput, BForm, BSpinner, VBModal, BFormTextarea,
  BAlert,
  BListGroupItem,
  BAvatar,
  BCardText,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuePhoneNumberInput from 'vue-phone-number-input'
import {
  required, email,
} from '@validations'
// eslint-disable-next-line import/no-cycle
import useSocietes from '@/composables/societeService'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
// eslint-disable-next-line import/no-cycle
import useCategorieSocietes from '@/composables/categorieSocieteService'
import Swal from 'sweetalert2'
// eslint-disable-next-line import/no-cycle
import useUsers from '@/composables/userService'

export default {
  components: {
    VuePhoneNumberInput,
    BRow,
    BCol,
    BCard,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BFormTextarea,
    BForm,
    BSpinner,
    ValidationObserver,
    ValidationProvider,
    vSelect,
    BBadge,
    BAlert,
    BListGroupItem,
    BAvatar,
    BCardText,

  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,

    Ripple,
  },
  setup() {
    const perPage = 10
    const pageOptions = [3, 5, 10]
    const totalRows = ref(1)
    const currentPage = ref(1)
    const sortBy = ''
    const sortDesc = false
    const sortDirection = 'asc'
    const filter = null
    const filterOn = []
    const errors = []

    const {
      getAllSocietes, societes, createSociete, updateSociete, loader, societeProcess, societeSuccess, deleteSociete,
      addOrChangeSocieteManager,
    } = useSocietes()
    const {
      categorieSocietes, getAllCategorieSocietes, userProcess,
    } = useCategorieSocietes()
    const {
      findUserByEmailOrMatricule, user,
    } = useUsers()

    const fields = [
      { key: 'index', label: 'N°' },
      { key: 'company_name', label: 'Nom de la Société', sortable: true },
      { key: 'manager.name', label: 'Responsable ', sortable: true },
      { key: 'categorie_societe.title', label: 'Catégorie société', sortable: true },
      { key: 'status', label: 'Status', sortable: true },
      { key: 'actions' },
    ]

    const form = reactive({
      id: null,
      manager_id: null,
      company_name: '',
      description: '',
      categorie_societe_id: null,
      email: '',
      phone: '',
      trade_register: '',
      ifu: '',
      headquarter: '',
      telephonefull: '',
    })
    const formSearch = reactive({
      email: '',
    })

    // Variable ref de validation du modal
    const societeFormRules = ref('')
    // Variable ref du modal
    const myModal = ref('')
    const managerForm = reactive({
      societe_id: null,
      manager_id: null,
    })
    // Fonction du création ou de modification si form.id n'est pas null
    const foundUser = ref(true)
    const searchUserSubmitModal = () => {
      societeFormRules.value.validate().then(async success => {
        if (success) {
          await findUserByEmailOrMatricule({ ...formSearch })
          if (user.value.length === 0) foundUser.value = false
          else { foundUser.value = true; managerForm.manager_id = user.value.id }
        }
      })
    }
    const handleSubmitModal = () => {
      societeFormRules.value.validate().then(async success => {
        if (success) {
          if (form.id === null) await createSociete({ ...form })
          else await updateSociete({ ...form })
          if (societeSuccess.value) myModal.value.toggle('#toggle-btn')
        }
      })
    }

    //  Fonction de soumission du modal
    const submitForm = bvModalEvt => {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      handleSubmitModal()
    }

    const telephonefull = ref('')
    // Reset Form
    const resetForm = () => {
      form.id = null
      form.manager_id = null
      managerForm.manager_id = null
      form.company_name = ''
      form.description = ''
      form.categorie_societe_id = ''
      form.email = ''
      form.phone = ''
      form.trade_register = ''
      form.ifu = ''
      form.headquarter = ''
      form.telephonefull = ''
      formSearch.email = ''
      foundUser.value = true
      user.value = []
    }

    const addManager = async () => {
      if (managerForm.manager_id === null) {
        Swal.fire({
          title: 'Message',
          text: 'Vous devez choisir un responsable',
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-success btn-sm',
          },
          confirmButtonText: 'OK',
        })
      } else {
        await addOrChangeSocieteManager({ ...managerForm })
        resetForm()
      }
    }
    // eslint-disable-next-line no-unused-vars
    watch(() => (form.telephonefull), () => {
      form.phone = ''
      form.phone = telephonefull.value.formattedNumber
      console.clear()
    })

    const onFiltered = filteredItems => {
      // Trigger pagination to update the number of buttons/pages due to filtering
      totalRows.value = filteredItems.length
      currentPage.value = 1
    }

    watch(() => formSearch.email, () => {
      user.value = []
      managerForm.manager_id = null
      foundUser.value = true
    })

    const deletingSociete = async id => {
      Swal.fire({
        title: 'Suppression',
        text: 'Etes-vous sûr de vouloir supprimer cette société ?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-sm',
          cancelButton: 'btn btn-danger btn-sm ml-1',
        },
        cancelButtonText: 'Annuler',
        confirmButtonText: 'Supprimer',
      }).then(async result => {
        if (result.isConfirmed) {
          await deleteSociete(id)
          await getAllSocietes()
          Swal.fire({

            title: 'Supprimée!',
            text: 'Société supprimée avec succès.',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-success btn-sm',
            },
            confirmButtonText: 'Ok',

          })
        }
      })
    }

    // Récupérer un enregistrement
    const getItem = async item => {
      form.id = item.id
      managerForm.societe_id = item.id
      form.phone = item.phone
      form.telephonefull = item.phone
      form.manager_id = item.manager_id
      form.company_name = item.company_name
      form.description = item.description
      form.categorie_societe_id = +item.categorie_societe_id
      form.email = item.email
      form.trade_register = item.trade_register
      form.ifu = item.ifu
      form.headquarter = item.headquarter
    }

    const formatter = value => value.toUpperCase()
    const sortOptions = computed(() => fields.filter(f => f.sortable).map(f => ({ text: f.label, value: f.key })))

    const statusSocieteVariant = status => {
      if (status === 'Actif') return 'success'
      if (status === 'Suspendu') return 'danger'
      return 'primary'
    }

    onMounted(async () => {
      await getAllSocietes()
      await getAllCategorieSocietes()
      // Set the initial number of items
      totalRows.value = societes.value.length
    })

    return {
      searchUserSubmitModal,
      user,
      categorieSocietes,
      addManager,
      formSearch,
      deletingSociete,
      societeFormRules,
      submitForm,
      sortOptions,
      foundUser,
      statusSocieteVariant,
      getItem,
      loader,
      societeProcess,
      societeSuccess,
      societes,
      pageOptions,
      perPage,
      userProcess,
      totalRows,
      currentPage,
      sortBy,
      sortDesc,
      sortDirection,
      filter,
      telephonefull,
      filterOn,
      fields,
      myModal,
      onFiltered,
      form,
      errors,
      formatter,
      resetForm,
      required,
      email,
      avatarText,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
